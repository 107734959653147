<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="800"
    v-if="isDataLoadedFromServer"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Add New Discover</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="dialog = false"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->

        <form id="createForm" class="pa-3 pb-0">
          <v-select
            v-model="formData.store_id"
            label="Store"
            item-text="text"
            item-value="index"
            :items="serverData.stores"
            clearable
            outlined
            :error-messages="store_idErrors"
            @input="$v.formData.store_id.$touch()"
            @blur="$v.formData.store_id.$touch()"
          ></v-select>

          <v-text-field
            v-model="formData.url"
            label="URL"
            clearable
            outlined
            :error-messages="urlErrors"
            @input="$v.formData.url.$touch()"
            @blur="$v.formData.url.$touch()"
          ></v-text-field>
          <v-file-input
            class="col-12"
            v-model="formData.image"
            show-size
            counter
            multiple
            dense
            outlined
            accept="image/png, image/jpeg, image/bmp"
            prepend-icon="mdi-camera"
            label="Image"
            clearable
            :error-messages="imageErrors"
            @input="$v.formData.image.$touch()"
            @blur="$v.formData.image.$touch()"
          ></v-file-input>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="mb-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetCreateForm"
          >
            Clear
          </button>
          <button
            type="submit"
            class="btn btn-info px-5 py-3 ls1"
            @click="submitCreateForm"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import axios from "axios";
import { getToken } from "@/core/services/jwt.service";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, url } from "vuelidate/lib/validators";

export default {
  name: "AddItem",
  mixins: [validationMixin],
  validations: {
    formData: {
      store_id: { required },
      image: { required },
      url: { required, url },
    },
  },
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    serverData: null,
    formData: {
      store_id: null,
      image: null,
      url: null,
    },
  }),
  beforeMount() {
    this.loadDataFromServer();
  },
  methods: {
    handleFormValidation(fieldName, vueObj) {
      const errors = [];
      if (!vueObj.$v.formData[fieldName].$dirty) return errors;
      if ("required" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].required &&
          errors.push("This field is required");
      }
      if ("url" in vueObj.$v.formData[fieldName]) {
        !vueObj.$v.formData[fieldName].url &&
          errors.push("This url is invalid");
      }
      return errors;
    },
    loadDataFromServer() {
      this.pageLoader(true);
      let config = {
        method: "post",
        url: `${process.env.VUE_APP_BASE_URL}/stores/portal/discovers/create`,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${getToken()}`,
        },
      };
      axios(config)
        .then((response) => {
          this.serverData = response.data;
          this.pageLoader(false);
        })
        .catch((error) => {
          Swal.fire({
            title: "Error",
            text: `${error.response.data?.message}`,
            icon: "error",
            showConfirmButton: false,
            timer: 2500,
          });
          this.dialog = false;
          this.loader(false);
        });
    },
    toggleModal() {
      this.dialog = !this.dialog;
    },
    convertToFormData() {
      let data = new FormData();
      Object.entries(this.formData).forEach((item) => {
        if ((item[1] && item[0] != "image") || item[1] == 0) {
          data.append(item[0], item[1]);
        } else if (item[1] && item[0] == "image") {
          data.append(item[0], item[1][0]);
        }
      });
      return data;
    },
    submitCreateForm() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.pageLoader(true);
        // let data = JSON.stringify(this.entCreate);
        let data = this.convertToFormData();
        let config = {
          method: "post",
          url: `${process.env.VUE_APP_BASE_URL}/stores/portal/discovers/store`,
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${getToken()}`,
          },
          data: data,
        };
        axios(config)
          .then(() => {
            Swal.fire({
              title: "Created",
              text: `Discover successfully created!`,
              icon: "success",
              showConfirmButton: false,
              timer: 2500,
            });
            this.resetCreateForm();
            this.toggleModal();
            this.refresher();
          })
          .catch((error) => {
            Swal.fire({
              title: "Error",
              text: `${error.response.data?.message}`,
              icon: "error",
              showConfirmButton: false,
              timer: 2500,
            });
            this.pageLoader(false);
          });
      }
    },
    resetCreateForm() {
      this.$v.$reset();
      this.formData = {
        store_id: null,
        image: null,
        url: null,
      };
    },
  },
  computed: {
    isDataLoadedFromServer: function () {
      return !!this.serverData;
    },
    store_idErrors: function () {
      return this.handleFormValidation("store_id", this);
    },
    urlErrors: function () {
      return this.handleFormValidation("url", this);
    },
    imageErrors: function () {
      return this.handleFormValidation("image", this);
    },
  },
};
</script>
